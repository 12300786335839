import React from "react";
import "./Home.scss";
import { default as illustration } from '../../assets/images/ERP_Illustration.svg'
import ButtonCallToAction, { ButtonType } from "../../components/ButtonCallToAction/ButtonCallToAction";
import YellowTextUnderLine from "../../components/YellowTextUnderline/YellowTextUnderLine";
import NumberedDescription from "../../components/NumberedDescription/NumberedDescription";

import { useTranslation } from 'react-i18next';


//import Carousel from 'react-bootstrap/Carousel';
//import Carousel from "../../components/Carousel/Carousel";
import CarouselSlideShow from "../../components/CarouselSlideShow/CarouselSlideShow";

function Home() {
    const { t } = useTranslation();

    // const slides = [
    //     {
    //         testemonial: "test1",
    //         name: "test1"
    //     },
    //     {
    //         testemonial: "test2",
    //         name: "test2"
    //     },
    //     {
    //         testemonial: "test3",
    //         name: "test3"
    //     }]
    return (
        <>

            <div className="Hero">
                <div>
                    <h1>{t("homePage.hero.title")}</h1>
                    <ButtonCallToAction text={t("homePage.hero.buttonText")} buttonType={ButtonType.full} onClickHandle="software" />
                </div>
                <div>
                    <img className="Illustration" src={illustration} alt="ERP Illustration" />
                </div>

            </div>
            <div className="CallToAction">
                <div className="HeaderContainer">
                    <div>
                        <h2>{t("homePage.pitchArea.title")}</h2>
                        <YellowTextUnderLine />
                    </div>
                    <p>{t("homePage.pitchArea.description")}</p>
                </div>
                <div className="Home_numberDescriptionContainer">
                    <NumberedDescription
                        number={"01"}
                        title={t("homePage.pitchArea.point1.title")}
                        description={t("homePage.pitchArea.point1.content")}
                    />
                    <NumberedDescription
                        number={"02"}
                        title={t("homePage.pitchArea.point2.title")}
                        description={t("homePage.pitchArea.point2.content")}
                    />
                    <NumberedDescription
                        number={"03"}
                        title={t("homePage.pitchArea.point3.title")}
                        description={t("homePage.pitchArea.point3.content")}
                    />
                </div>
                <ButtonCallToAction text={t("homePage.pitchArea.buttonText")} buttonType={ButtonType.stroke} onClickHandle="request-demo" />
            </div>

            {/* <div className="Testemonials">
                <p>The Power of <span>Cylabh</span> in Our Clients Words</p>
            </div> */}

            {/* <CarouselSlideShow /> */}

            {/* IMPORTANT!!! uncomment bootstrap in index.tsx to use
        <Carousel className="Carousel_container">
            {slides.map((slide)=>
            <Carousel.Item className="Carousel_item">
                <div> 
                    <h1>{slide.testemonial}</h1>
                    <h2>{slide.name}</h2>
                </div>
            </Carousel.Item>
            )}

        </Carousel>*/}


            {/*<Carousel slides={slides} interval={5000} />*/}

        </>
    );
}

export default Home;