import React from "react";
import "./ImageHero.scss";
import ButtonCallToAction, { ButtonType } from "../ButtonCallToAction/ButtonCallToAction";
import { default as DownArrow } from '../../assets/images/DownArrow.png'


interface ImageHeroProps {
  header: string, 
  subHeader?: any,
  image: any, 
  buttonText: string,
  downArrowLinkReference: string
}

function ImageHero({header, subHeader = "", image, buttonText, downArrowLinkReference}: ImageHeroProps) {
    return (
      <>
      <div className="imageHero">
        <img src={image} />
        <span></span>
        <div className="imageHero_textContainer">
          <h1 className="imageHero_header">{header}</h1>
          {subHeader!= "" &&
          <h1 className="imageHero_subHeader">{subHeader}</h1>
          }
          
          <ButtonCallToAction text={buttonText} buttonType={ButtonType.full} onClickHandle="request-demo"/>
        </div>
      </div>
      <div className="downArrowContainer ">
      <a className="downArrow" href={"#" + downArrowLinkReference}>
        <img  src={DownArrow} alt="Down Arrow" />
      </a>
      </div>
      
      </>
    );
  };
  
  export default ImageHero;