import "./PrivacyPolicy.scss";
import { useTranslation } from "react-i18next";
import YellowTextUnderLine from "../../components/YellowTextUnderline/YellowTextUnderLine";

function PrivacyPolicy() {
    const { t } = useTranslation();
    return (
        <div className="privacyPolicy">
            <h1>{t("privacyPolicyPage.title")}</h1>
            <YellowTextUnderLine/>
            <p>{t("privacyPolicyPage.content")}</p>
        </div>
    );
  };
  
  export default PrivacyPolicy;