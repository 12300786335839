import "./Contact.scss"
import { useTranslation } from "react-i18next";
import YellowTextUnderLine from "../../components/YellowTextUnderline/YellowTextUnderLine";

function Contact() {
  const { t } = useTranslation();
  return (
    <div className="contactUs">
      <h1>{t("contactUsPage.title")}</h1>
      <YellowTextUnderLine />
      <h2>{t("contactUsPage.subTitle")}</h2>
      <p>{t("contactUsPage.contactInfo")}</p>
      <a href="/request-demo">{t("contactUsPage.buttonText")}</a>
    </div>
  );
};

export default Contact;