import "./TermsOfServices.scss"
import { useTranslation } from "react-i18next";
import YellowTextUnderLine from "../../components/YellowTextUnderline/YellowTextUnderLine";

function TermsOfServices() {
    const { t } = useTranslation();
    return (
        <div className="termsOfServices">
          <h1>{t("termsOfServicesPage.title")}</h1>
          <YellowTextUnderLine/>
          <p>{t("termsOfServicesPage.content")}</p>
      </div>
    );
  };
  
  export default TermsOfServices;