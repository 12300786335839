import React from 'react';
import { Suspense } from 'react';
import './App.scss';
import Header from "./sections/Header/Header";
import Footer from "./sections/Footer/Footer";
import Home from './pages/Home/Home';
import About from './pages/About/About';
// import Software from './pages/Software/Software';
import Contact from './pages/Contact/Contact';
import RequestADemo from './pages/RequestADemo/RequestADemo';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import TermsOfServices from './pages/TermsOfServices/TermsOfServices';
import { Routes, Route } from "react-router-dom";
const Software = React.lazy(() => import("./pages/Software/Software"));



function App() {
  return (
    <Suspense fallback="...loading">
    <div className="App">
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/software" element={<Software />} />
        <Route path="/contact" element={<Contact />} />
        {/* remove this when the form is available */}
        {/* <Route path="/request-demo" element={<RequestADemo />} /> */}
        <Route path="/request-demo" element={<Contact />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-services" element={<TermsOfServices />} />
        <Route path="*" element={<Home />} />
      </Routes>
      <Footer />
    </div>
    </Suspense>
  );
}

export default App;
