import React, { useState, useEffect } from "react";
import { default as logo } from '../../assets/images/Logo_Cylabh.svg'
import "./Header.scss";
import { Link } from "react-router-dom";
import { default as getSnapshot } from "../../utils/WindowSize";
import { useTranslation } from 'react-i18next';

function Header() {
  // Record the scroll position of the page and rerender on change.
  const [scrollTop, setScrollTop] = useState<number>(0);
  // record the Hamberger menu state and rerender on change.
  const [hambergerActive, setHambergerActive] = useState<boolean>(false);

  // change the state of ScrollTop when the scroll position of the page changes. 
  useEffect(() => {
    const handleScroll = (event: any) => {
      setScrollTop(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Initializing the i18n instances. 
  const { t, i18n } = useTranslation();

  // Change the language of the website when user click on the language selector. 
  const changeLanguage = () => {
    i18n.language !== "fr" ? i18n.changeLanguage("fr") : i18n.changeLanguage("en");
    setHambergerActive(false);
  };

  // Get the current route of the user.
  let currentLocation = window.location.pathname;

  // Function that handles the click event of hamberger menu.
  const handleHambergerClick = () => {
    setHambergerActive(!hambergerActive);
  };

  // Function that closes the hamberger menu
  const closeHamberger= () => {
    setHambergerActive(false);
  };

  // Function that handles css to apply to the main container of the header.
  const handleHeaderMainContainerCssState = () => {
    const windowDimentions = getSnapshot();
    let componentClasses = ""
    
    componentClasses += "headerMainContainer" + " " + (scrollTop !== 0 && "headerMainContainerAfterScroll");
    if (hambergerActive) {
      componentClasses += " " + "hambergerIsActive";
    }
    return componentClasses;
  }

  return (
    <div className={handleHeaderMainContainerCssState()}>
      <div className="header_HambergerMenu">
        <svg onClick={handleHambergerClick} width="48" height="29" viewBox="0 0 48 29" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect className={"" + (hambergerActive && "hamberger_top")} width="48" height="3" rx="1.5" fill="white" />
          <rect className={"" + (hambergerActive && "hamberger_bottom")} y="26" width="48" height="3" rx="1.5" fill="white" />
          <rect className={"" + (hambergerActive && "hamberger_middle")} y="13" width="48" height="3" rx="1.5" fill="white" />
        </svg>
      </div>
      <Link className="menuChoice" to="/" >
        <img src={logo} alt="Cylabh" />
      </Link>
      <div onClick={closeHamberger} className="menu">
        <Link className={(currentLocation === "/" && !hambergerActive) ? "menuChoice currentPageSelected" : "menuChoice"} to="/" >{t("header.menu.home")}</Link>
        <Link className={(currentLocation === "/about" && !hambergerActive) ? "menuChoice currentPageSelected" : "menuChoice"} to="/about" >{t("header.menu.about")}</Link>
        <Link className={(currentLocation === "/software" && !hambergerActive) ? "menuChoice currentPageSelected" : "menuChoice"} to="/software" >{t("header.menu.software")}</Link>
        <Link className={(currentLocation === "/contact" && !hambergerActive) ? "menuChoice currentPageSelected" : "menuChoice"} to="/contact" >{t("header.menu.contact")}</Link>
      </div>
      <button className="language" onClick={changeLanguage} >{t("header.languageSelection")}</button>
    </div>
  );
}

export default Header;