import "./About.scss"
import ImageHero from "../../components/ImageHero/ImageHero";
import YellowTextUnderLine from "../../components/YellowTextUnderline/YellowTextUnderLine";
import { default as AboutImage } from '../../assets/images/About.jpg'
import { useTranslation } from "react-i18next";


function About() {
  const { t } = useTranslation();
  return (
    <>
      <ImageHero
        header={t("aboutPage.hero.title")}
        subHeader={t("aboutPage.hero.subTitle")}
        image={AboutImage}
        buttonText={t("aboutPage.hero.buttonText")}
        downArrowLinkReference="our-vision"
      />
      <div className="About_text" id="our-vision">
        <h2>{t("aboutPage.ourVision.title")}</h2>
        <YellowTextUnderLine />
        <p className="quote">{t("aboutPage.ourVision.quote")}</p>
        <p>{t("aboutPage.ourVision.content")}</p>
        <h2>{t("aboutPage.ourMission.title")}</h2>
        <YellowTextUnderLine />
        <p>{t("aboutPage.ourMission.content")}</p>
      </div>

    </>
  );
};

export default About;