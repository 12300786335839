import React from "react";
import "./NumberedDescription.scss";

export interface NumberedDescriptionProps {
    number: string,
    title: string, 
    description: string
}

function NumberedDescription({ number, title, description }: NumberedDescriptionProps) {
    return (
        <div className="NumberedDescription_container">
            <div className="NumberedDescription_numberTitleContainer">
                <p>{number}</p>
                <h3>{title}</h3>
            </div>
            <p className="NumberedDescription_description">{description}</p>
        </div>
    );
}

export default NumberedDescription;