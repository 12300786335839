import React from "react";
import "./ButtonCallToAction.scss";
import { useNavigate } from "react-router-dom";

export enum ButtonType {
    full = "full", 
    stroke = "stroke"
  }

export interface ButtonCallToActionsProps {
    text: string,
    buttonType: ButtonType,
    onClickHandle: string
}


function ButtonCallToAction({ text, buttonType, onClickHandle }: ButtonCallToActionsProps) {
    
    let navigate = useNavigate();
    const routeChange = () =>{ 
        let path = `/` + onClickHandle; 
        navigate(path);
      }
    return (
        <>
        <button className={buttonType=="full" ? "button_full" : "button_stroke"} onClick={routeChange}>{text}</button>
        </>
    );
}

ButtonCallToAction.defaultProps = {
    buttonType: ButtonType.full
}

export default ButtonCallToAction;